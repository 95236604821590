.ModalContainer {
  padding: 10px 35px 35px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 300;
  line-height: '1.5em';
  min-width: 520px;
}

.closeBtn {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin-left: 553px;
  margin-top: 12px;
}

.closeBtn svg {
  width: 19px;
  height: 19px;
}

.closeBtn line {
  stroke: #575757;
  stroke-width: 2.5;
  fill: #575757;
}

.ModalHeader {
  font-size: 20px;
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
}

.TableContainer {
  margin-bottom: 50px;
}

.TableHeader {
  color: var(--primary-red);
  padding-right: 20px;
}

.Row {
  border-bottom: 0.9px solid #39393933;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
}

.TitleWrap {
  background-color: #f5f5f5;
  padding: 16px 0 16px 16px;
  width: 100%;
  display: flex;
}

.Title {
  font-weight: 500;
  margin-right: 5px;
}

.SubTitle {
  text-align: end;
  padding-right: 16px;
}

.ModalInformation {
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
}
