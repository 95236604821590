.StateIconContainer {
  background-color: #edededab;
}

.StateIcon {
  transform: scale(0.7, 0.7);
}

.StateIcon path {
  fill: #8d8d8dde;
}

.StateIconContainer,
.StateIconAproveContainer,
.StateIconRejectedContainer {
  background-color: #edededab;
  width: 63px;
  height: 25px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.StateIconAprove {
  transform: scale(0.7, 0.7);
}

.StateIconAprove path {
  fill: #56c871de;
}

.StateIconRejectedContainer {
  background-color: #ff950021;
}

.StateIconRejected {
  transform: scale(0.7, 0.7);
}

.StateIconRejected path {
  fill: #ed771dde;
}
