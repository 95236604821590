.Text {
  font-weight: 500;
  font-size: 0.8rem;
}

@media (min-width: 560px) {
  .Text {
    text-align: center;
    font-weight: 600;
  }
}
