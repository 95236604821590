.NumericInformation {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.NumericDataDiv {
  width: 90%;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  background-color: #f5f5f5;
  margin: 0.375rem auto;
}

.NumericDataTitle {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0 auto;
}

.NumericDataValue {
  margin: 0 auto;
  color: var(--primary-red);
  font-size: 2rem;
  font-weight: bold;
}

.Successful {
  color: red;
  width: 10px;
}

.ChartDiv {
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 10px 15px;
  margin-top: 2rem;
}

.CustomLabel {
  transform: translateX(6px);
}

@media (min-width: 960px) {
  .NumericInformation {
    display: grid;
    grid-template-columns: repeat(3, calc(33% - 1rem));
    gap: 1rem;
  }

  .NumericDataDiv {
    margin: 0 auto;
  }
}
