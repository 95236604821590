.Active {
  background: linear-gradient(to bottom, #ec1f39f2 0%, #ec1f39ff 100%);
  box-shadow: 0 12px 20px -10px rgba(215, 3, 12, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
    0 7px 8px -5px rgba(215, 3, 12, 0.2);
  transition: all 100ms linear;
  border-radius: 3px;
  width: 90%;
  margin: 0 auto;
  color: #1470af !important;
}

.DrawerIconPositionOpen {
  position: absolute !important;
  top: 10px;
  right: 0;
  z-index: 6 !important;
  height: 20px;
  width: 20px;
  color: #ffffff !important;
}

.HideIconOpen {
  color: #ffffff !important;
}

.DrawerIconPositionClosed {
  height: 20px;
  width: 20px;
  position: absolute !important;
  top: 10px;
  z-index: 10;
}

.LogoutWrap {
  font-family: Roboto;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  margin: 24px;
  border-color: #ffffff;
  display: flex;
  align-items: center;
}

.LogoutIcon {
  transform: scale(1, 0.9);
  width: 22px;
  fill: #ffffff;
  margin-right: 50px;
}
