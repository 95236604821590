.PaginationContainer {
  width: 100%;
}

.Pagination .PageItem {
  display: inline-block;
  border-radius: 2px;
  text-align: center;
  vertical-align: top;
  height: 30px;
}

.Pagination .PageLink {
  color: #444;
  cursor: pointer;
  padding: 0 12px;
  line-height: 30px;
  outline: none;
  text-decoration: none;
}

.PaginationContainer {
  text-align: center;
}

.Pagination {
  text-align: center;
  padding: 0;
  margin-top: 25px;
  width: 100%;
} /*

/* padding: 4px 0px 8px 0px; 

*/

.Active {
  background-color: var(--primary-red);
  color: #fff;
}

.Active a {
  color: white !important;
}

.Pagination li:hover:not(.Active) {
  background-color: #ddd;
}

.Disabled a {
  cursor: default;
  color: #999;
}

.ChevronIcon {
  margin-top: 2px;
}
