@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap&family=Roboto:wght@300;400;500;700;900&display=swap&family=Open+Sans:wght@500&display=swap);
.Spinner_Spinner__BWqNs {
  width: 100px !important;
  height: 100px !important;
  margin: 0 auto !important;
  display: block !important;
}

.Select_Wrapper__2e838 {
  display: inline-block;
  width: 100%;
}

.Select_CustomMenu__3JXYn {
  z-index: 4 !important;
}

.Select_CustomSelect__3tU4o {
  border: 1px solid hsl(0, 0%, 80%) !important;
  width: 160px;
  color: #1470af !important;
  border-radius: 2px !important;
  text-transform: uppercase;
  box-shadow: none !important;
  background: white !important;
  font-size: 14px;
  margin-top: 35px;
}

.Select_FullWidth__3NkTG {
  width: 100% !important;
}

.Select_FullWidthWrapper__26f8d {
  width: 200px;
}

.Select_CustomSelect__3tU4o:hover {
  border: 1px solid hsl(0, 0%, 80%) !important;
}

.Select_CustomOption__1-goG {
  background-color: transparent !important;
  color: #1470af !important;
  text-align: left;
  text-transform: uppercase;
}

.Select_CustomOption__1-goG:hover {
  background-color: rgba(0, 0, 0, 0.08) !important;
  color: #1470af !important;
}

.Select_DropdownIcon__3wMtC {
  color: #1470af;
  transform: rotate(90deg);
}

@media (min-width: 960px) {
  .Select_CustomSelect__3tU4o {
    font-size: 16px;
  }
}

.OutlinedSelect_FormControl__3Nxmf {
  text-align: left !important;
  width: 100% !important;
  height: 50px !important;
}

.Input_TextField__3zMxN,
.Input_Textarea__1ctXu {
  width: 90%;
}

.Input_Input__1jrD6 {
  width: 100% !important;
}

.Input_Recaptcha__P3rdm {
  width: 98%;
  margin: 0 auto;
  margin-top: 15px;
}

.Input_Dense__3zvyT {
  margin-top: 16px !important;
}

.Input_Textarea__1ctXu {
  width: 90%;
}

.Input_Checkbox__2hl1M {
  margin-left: 5% !important;
}

.Input_SelectContainer__2BQx6 {
  width: 80%;
}

.Input_Invalid___jUhL {
  border: 1px solid red;
  background-color: salmon;
}

.Input_ValidationError__1DHLe {
  color: red;
  margin: 5px 0;
}

.Input_OutlinedSelect__3zphi {
  width: 100% !important;
}

.Input_ReactDatePicker__1K3bl > div {
  display: block !important;
}

.Input_ReactDatePicker__1K3bl > div > div {
  width: 100%;
}

.Input_Dense__3zvyT {
  margin-top: 16px !important;
  z-index: 0;
}

.Input_FullWidth__xjVLt {
  width: 100% !important;
}

.Input_InputPlaceholder__197Om::-webkit-input-placeholder {
  color: black;
  outline: 0;
  opacity: 1 !important;
}

.Input_InputPlaceholder__197Om:-ms-input-placeholder {
  color: black;
  outline: 0;
  opacity: 1 !important;
}

.Input_InputPlaceholder__197Om::placeholder {
  color: black;
  outline: 0;
  opacity: 1 !important;
}

.Input_Label__3Bw3U {
  color: rgba(0, 0, 0, 1);
  padding: 0 0 0.9rem 0;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  line-height: 1;
}

.Input_DatePickerMainDiv__2Tmwy > div {
  display: block !important;
}

.Input_DatePickerMainDiv__2Tmwy > div > div {
  width: 100%;
}

@media (min-width: 960px) {
  .Input_TextField__3zMxN {
    width: 80%;
  }

  .Input_Recaptcha__P3rdm {
    width: 80%;
  }

  .Input_SmallInput__3AuEm {
    height: 25px !important;
    padding: 7px;
    font-size: 12px;
  }

  .Input_Label__3Bw3U {
    padding-bottom: 0.5rem;
  }
}

@media (min-width: 1600px) {
  .Input_SmallInput__3AuEm {
    height: 19px !important;
    padding: 15px 14px;
    font-size: 1rem;
  }

  .Input_Label__3Bw3U {
    padding-bottom: 0.8rem;
  }
}

.PaginationContainer {
  width: 100%;
}

.Pagination .PageItem {
  display: inline-block;
  border-radius: 2px;
  text-align: center;
  vertical-align: top;
  height: 30px;
}

.Pagination .PageLink {
  color: #444;
  cursor: pointer;
  padding: 0 12px;
  line-height: 30px;
  outline: none;
  text-decoration: none;
}

.PaginationContainer {
  text-align: center;
}

.Pagination {
  text-align: center;
  padding: 0;
  margin-top: 25px;
  width: 100%;
} /*

/* padding: 4px 0px 8px 0px; 

*/

.Active {
  background-color: var(--primary-red);
  color: #fff;
}

.Active a {
  color: white !important;
}

.Pagination li:hover:not(.Active) {
  background-color: #ddd;
}

.Disabled a {
  cursor: default;
  color: #999;
}

.ChevronIcon {
  margin-top: 2px;
}

.CardBody {
  padding: 50px !important;
}

.Title {
  padding-bottom: 16px;
}

.CardBody {
  padding: 50px !important;
}

.Title {
  padding-bottom: 16px;
}

.LeadDetail_ModalContainer__JOSO4 {
  padding: 10px 35px 35px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 300;
  line-height: '1.5em';
  min-width: 520px;
}

.LeadDetail_closeBtn__Zua7y {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin-left: 553px;
  margin-top: 12px;
}

.LeadDetail_closeBtn__Zua7y svg {
  width: 19px;
  height: 19px;
}

.LeadDetail_closeBtn__Zua7y line {
  stroke: #575757;
  stroke-width: 2.5;
  fill: #575757;
}

.LeadDetail_ModalHeader__3rRhV {
  font-size: 20px;
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
}

.LeadDetail_TableContainer__3MMlY {
  margin-bottom: 50px;
}

.LeadDetail_TableHeader__1p4wp {
  color: var(--primary-red);
  padding-right: 20px;
}

.LeadDetail_Row__3QajE {
  border-bottom: 0.9px solid #39393933;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
}

.LeadDetail_TitleWrap__39qhj {
  background-color: #f5f5f5;
  padding: 16px 0 16px 16px;
  width: 100%;
  display: flex;
}

.LeadDetail_Title__3rJK7 {
  font-weight: 500;
  margin-right: 5px;
}

.LeadDetail_SubTitle__3XwzB {
  text-align: end;
  padding-right: 16px;
}

.LeadDetail_ModalInformation__2CYW0 {
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
}

.LeadState_StateIconContainer__1Vcdx {
  background-color: #edededab;
}

.LeadState_StateIcon__3t-6V {
  transform: scale(0.7, 0.7);
}

.LeadState_StateIcon__3t-6V path {
  fill: #8d8d8dde;
}

.LeadState_StateIconContainer__1Vcdx,
.LeadState_StateIconAproveContainer__3G2LK,
.LeadState_StateIconRejectedContainer__1a9BJ {
  background-color: #edededab;
  width: 63px;
  height: 25px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LeadState_StateIconAprove__2QGfA {
  transform: scale(0.7, 0.7);
}

.LeadState_StateIconAprove__2QGfA path {
  fill: #56c871de;
}

.LeadState_StateIconRejectedContainer__1a9BJ {
  background-color: #ff950021;
}

.LeadState_StateIconRejected__JBKWo {
  transform: scale(0.7, 0.7);
}

.LeadState_StateIconRejected__JBKWo path {
  fill: #ed771dde;
}

.CSVDownloader_CSVIcon__Xewln {
  width: 60px;
}

.CSVDownloader_CSVIcon__Xewln path {
  stroke: #ffffff;
  stroke-width: 1.5;
}

.Leads_FilterMainDiv__34iHb {
  margin: 0 !important;
}

.Leads_FilterContainer__2y7wa {
  margin-bottom: 30px;
}

.Leads_DateInput__170Ma {
  position: relative;
  padding: 0 30px !important;
}

.Leads_SearchButton__DIUWX {
  height: 40px !important;
  margin: 8px 0 4px !important;
  width: 100%;
}

@media (max-width: 950px) {
  .Leads_DateInput__170Ma {
    padding: 0 !important;
  }
}

@media (min-width: 950px) {
  .Leads_FilterMainDiv__34iHb,
  .Leads_ExportCsv__1yFCb {
    padding: 0 !important;
  }

  .Leads_DateInput__170Ma {
    padding: 0 15px !important;
  }
}

.Leads_TableTitle__1XKPH {
  display: flex;
  align-items: center;
}

.Leads_CSVIcon__GQY6- {
  margin-top: 5px;
  margin-left: auto;
}

.Leads_AmountIcon__HFEXP {
  margin-top: 7px;
  max-width: 15px;
  fill: #696969de;
  stroke: #696969de;
}

@media (max-width: 1100px) {
  .Leads_AmountIcon__HFEXP {
    min-width: 13px;
  }
}

.Leads_NotCompleted__55eMx {
  margin-left: 6px;
}

.CustomLabel_Text__3jd_B {
  font-weight: 500;
  font-size: 0.8rem;
}

@media (min-width: 560px) {
  .CustomLabel_Text__3jd_B {
    text-align: center;
    font-weight: 600;
  }
}

.Graph_ChartDiv__VKqBB {
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 10px 15px;
  margin-top: 2rem;
  width: 100%;
}

.Dashboard_NumericInformation__3q42M {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.Dashboard_NumericDataDiv__1HVhw {
  width: 90%;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  background-color: #f5f5f5;
  margin: 0.375rem auto;
}

.Dashboard_NumericDataTitle__8X2RQ {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0 auto;
}

.Dashboard_NumericDataValue__2tXcy {
  margin: 0 auto;
  color: var(--primary-red);
  font-size: 2rem;
  font-weight: bold;
}

.Dashboard_Successful__33v7_ {
  color: red;
  width: 10px;
}

.Dashboard_ChartDiv__E4rOa {
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 10px 15px;
  margin-top: 2rem;
}

.Dashboard_CustomLabel__15MIK {
  transform: translateX(6px);
}

@media (min-width: 960px) {
  .Dashboard_NumericInformation__3q42M {
    display: grid;
    grid-template-columns: repeat(3, calc(33% - 1rem));
    grid-gap: 1rem;
    gap: 1rem;
  }

  .Dashboard_NumericDataDiv__1HVhw {
    margin: 0 auto;
  }
}

.SideDrawer_Active__3pE9S {
  background: linear-gradient(to bottom, #ec1f39f2 0%, #ec1f39ff 100%);
  box-shadow: 0 12px 20px -10px rgba(215, 3, 12, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
    0 7px 8px -5px rgba(215, 3, 12, 0.2);
  transition: all 100ms linear;
  border-radius: 3px;
  width: 90%;
  margin: 0 auto;
  color: #1470af !important;
}

.SideDrawer_DrawerIconPositionOpen__16bMq {
  position: absolute !important;
  top: 10px;
  right: 0;
  z-index: 6 !important;
  height: 20px;
  width: 20px;
  color: #ffffff !important;
}

.SideDrawer_HideIconOpen__2_j8K {
  color: #ffffff !important;
}

.SideDrawer_DrawerIconPositionClosed__CkVL1 {
  height: 20px;
  width: 20px;
  position: absolute !important;
  top: 10px;
  z-index: 10;
}

.SideDrawer_LogoutWrap__nmTwB {
  font-family: Roboto;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  margin: 24px;
  border-color: #ffffff;
  display: flex;
  align-items: center;
}

.SideDrawer_LogoutIcon__2jbhi {
  transform: scale(1, 0.9);
  width: 22px;
  fill: #ffffff;
  margin-right: 50px;
}

.Input {
  width: 100% !important;
}

.Auth {
  background: #6f7779;
  width: 100%;
  height: 100vh;
}

.AuthFormContainer {
  display: table;
  width: 100%;
  height: 100%;
}

.AuthFormSubContainer {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.AuthForm {
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  background: white;
  padding: 40px;
  text-align: center;
}

.AuthSubmitButton {
  padding: 10px 40px !important;
  margin-top: 40px !important;
  color: #fff !important;
}

.AuthErrorMessage {
  color: red;
}

@media (min-width: 960px) {
  .AuthForm {
    max-width: 40%;
  }
}

.AuthLogo {
  max-width: 350px;
  margin: 32px;
}

:root {
  --primary-red: #ec1f39;
}

.App_App__1IQbR {
  text-align: center;
}

.App_App-logo__1sfRW {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App_App-logo__1sfRW {
    animation: App_App-logo-spin__29Xq1 infinite 20s linear;
  }
}

.App_App-header__1vz5u {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App_App-link__1IkWj {
  color: #61dafb;
}

@keyframes App_App-logo-spin__29Xq1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

