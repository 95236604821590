.FilterMainDiv {
  margin: 0 !important;
}

.FilterContainer {
  margin-bottom: 30px;
}

.DateInput {
  position: relative;
  padding: 0 30px !important;
}

.SearchButton {
  height: 40px !important;
  margin: 8px 0 4px !important;
  width: 100%;
}

@media (max-width: 950px) {
  .DateInput {
    padding: 0 !important;
  }
}

@media (min-width: 950px) {
  .FilterMainDiv,
  .ExportCsv {
    padding: 0 !important;
  }

  .DateInput {
    padding: 0 15px !important;
  }
}

.TableTitle {
  display: flex;
  align-items: center;
}

.CSVIcon {
  margin-top: 5px;
  margin-left: auto;
}

.AmountIcon {
  margin-top: 7px;
  max-width: 15px;
  fill: #696969de;
  stroke: #696969de;
}

@media (max-width: 1100px) {
  .AmountIcon {
    min-width: 13px;
  }
}

.NotCompleted {
  margin-left: 6px;
}
